import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EncounterComponent = _resolveComponent("EncounterComponent")!

  return (_ctx.data.isLoaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", null, [
          (_openBlock(), _createBlock(_component_EncounterComponent, {
            ref: "encounterComp",
            encounter: _ctx.data.encounter,
            key: _ctx.data.counter,
            onReload: _ctx.reload,
            onNewEncounter: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleNewEncounter()))
          }, null, 8, ["encounter", "onReload"]))
        ])
      ]))
    : _createCommentVNode("", true)
}