import ApiService from "@/core/services/ApiService";
import { processErrors } from "./errorsProcessing";

export const url = "api/v1/OccurrenceCode";

export async function searchOccurrenceCodes(request) {
    return await ApiService.post(url + "/search", request as never)
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(({ response }) => {
        processErrors(response);
      });
  }
  
  export async function searchDictOccurrenceCodes(request) {
    return await ApiService.post(url + "/searchDict", request as never)
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(({ response }) => {
        processErrors(response);
      });
  }
  
  export async function addOccurrenceCode(OccurrenceCode) {
    return await ApiService.post(url, OccurrenceCode as never)
    .then((res) => {
      return res.data;
    }).catch(({ response }) => {
      processErrors(response);
    });
  }
  
  export async function getOccurrenceCode(OccurrenceCodeId) {
    return await ApiService.get(url + "/" + OccurrenceCodeId)
      .then((res) => {
        const item = res.data;
        return item;
      })
      .catch(({ response }) => {
        processErrors(response);
      });
  }
  
  export async function updateOccurrenceCode(OccurrenceCode) {
    if (OccurrenceCode) {
      return await ApiService.put(url + "/" + OccurrenceCode.id, OccurrenceCode as never)
        .then((res) => {
          return res.data;
        })
        .catch(({ response }) => {
          processErrors(response);
        });
    }
  }