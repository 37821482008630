import ApiService from "@/core/services/ApiService";
import { processErrors } from "./errorsProcessing";

export const url = "api/v1/ConditionCode";

export async function searchConditionCode(request) {
    return await ApiService.post(url + "/search", request as never)
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(({ response }) => {
        processErrors(response);
      });
  }
  
  export async function searchDictConditionCode(request) {
    return await ApiService.post(url + "/searchDict", request as never)
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(({ response }) => {
        processErrors(response);
      });
  }
  
  export async function addConditionCode(ConditionCode) {
    return await ApiService.post(url, ConditionCode as never)
    .then((res) => {
      return res.data;
    }).catch(({ response }) => {
      processErrors(response);
    });
  }
  
  export async function getConditionCode(ConditionCodeId) {
    return await ApiService.get(url + "/" + ConditionCodeId)
      .then((res) => {
        const item = res.data;
        return item;
      })
      .catch(({ response }) => {
        processErrors(response);
      });
  }
  
  export async function updateConditionCode(ConditionCode) {
    if (ConditionCode) {
      return await ApiService.put(url + "/" + ConditionCode.id, ConditionCode as never)
        .then((res) => {
          return res.data;
        })
        .catch(({ response }) => {
          processErrors(response);
        });
    }
  }