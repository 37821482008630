import ApiService from "@/core/services/ApiService";
import { processErrors } from "./errorsProcessing";

export const url = "api/v1/ValueCode";

export async function searchValueCode(request) {
    return await ApiService.post(url + "/search", request as never)
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(({ response }) => {
        processErrors(response);
      });
  }
  
  export async function searchDictValueCode(request) {
    return await ApiService.post(url + "/searchDict", request as never)
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(({ response }) => {
        processErrors(response);
      });
  }
  
  export async function addValueCode(ValueCode) {
    return await ApiService.post(url, ValueCode as never)
    .then((res) => {
      return res.data;
    }).catch(({ response }) => {
      processErrors(response);
    });
  }
  
  export async function getValueCode(ValueCodeId) {
    return await ApiService.get(url + "/" + ValueCodeId)
      .then((res) => {
        const item = res.data;
        return item;
      })
      .catch(({ response }) => {
        processErrors(response);
      });
  }
  
  export async function updateValueCode(ValueCode) {
    if (ValueCode) {
      return await ApiService.put(url + "/" + ValueCode.id, ValueCode as never)
        .then((res) => {
          return res.data;
        })
        .catch(({ response }) => {
          processErrors(response);
        });
    }
  }