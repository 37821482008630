import ApiService from "@/core/services/ApiService";
import { processErrors } from "./errorsProcessing";

export const url = "api/v1/revenueCode";

export async function searchRevenueCodes(request) {
  return await ApiService.post(url + "/search", request as never)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function searchDictRevenueCodes(request) {
  return await ApiService.post(url + "/searchDict", request as never)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function searchByCodeRevenueCodes(request) {
  return await ApiService.post(url + "/searchByCode", request as never)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}


export async function addRevenueCode(RevenueCode) {
  return await ApiService.post(url, RevenueCode as never).catch(
    ({ response }) => {
      processErrors(response);
    }
  );
}

export async function getRevenueCode(RevenueCodeId) {
  return await ApiService.get(url + "/" + RevenueCodeId)
    .then((res) => {
      const item = res.data;
      return item;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function updateRevenueCode(RevenueCode) {
  if (RevenueCode) {
    return await ApiService.put(
      url + "/" + RevenueCode.id,
      RevenueCode as never
    )
      .then((res) => {
        return res;
      })
      .catch(({ response }) => {
        processErrors(response);
      });
  }
}

export async function deleteRevenueCode(RevenueCode) {
  if (RevenueCode) {
    await ApiService.delete(url + "/" + RevenueCode?.id)
      .then()
      .catch(({ response }) => {
        processErrors(response);
      });
  }
}
