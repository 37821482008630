import ApiService from "@/core/services/ApiService";
import { processErrors } from "./errorsProcessing";

export const url = "api/v1/Modifier";

export async function searchModifiers(request) {
  return await ApiService.post(url + "/search", request as never)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function searchDictModifiers(request) {
  return await ApiService.post(url + "/searchDict", request as never)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function addModifier(Modifier) {
  return await ApiService.post(url, Modifier as never)
  .then((res) => {
    return res.data;
  }).catch(({ response }) => {
    processErrors(response);
  });
}

export async function getModifier(ModifierId) {
  return await ApiService.get(url + "/" + ModifierId)
    .then((res) => {
      const item = res.data;
      return item;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function updateModifier(Modifier) {
  if (Modifier) {
    return await ApiService.put(url + "/" + Modifier.id, Modifier as never)
      .then((res) => {
        return res.data;
      })
      .catch(({ response }) => {
        processErrors(response);
      });
  }
}

export async function deleteModifier(Modifier) {
  if (Modifier) {
    await ApiService.delete(url + "/" + Modifier?.id)
      .then()
      .catch(({ response }) => {
        processErrors(response);
      });
  }
}
