import ApiService from "@/core/services/ApiService";
import { processErrors } from "./errorsProcessing";

export const url = "api/v1/OccurrenceSpanCode";

export async function searchOccurrenceSpanCodes(request) {
    return await ApiService.post(url + "/search", request as never)
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(({ response }) => {
        processErrors(response);
      });
  }
  
  export async function searchDictOccurrenceSpanCodes(request) {
    return await ApiService.post(url + "/searchDict", request as never)
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch(({ response }) => {
        processErrors(response);
      });
  }
  
  export async function addOccurrenceSpanCode(OccurrenceSpanCode) {
    return await ApiService.post(url, OccurrenceSpanCode as never)
    .then((res) => {
      return res.data;
    }).catch(({ response }) => {
      processErrors(response);
    });
  }
  
  export async function getOccurrenceSpanCode(OccurrenceSpanCodeId) {
    return await ApiService.get(url + "/" + OccurrenceSpanCodeId)
      .then((res) => {
        const item = res.data;
        return item;
      })
      .catch(({ response }) => {
        processErrors(response);
      });
  }
  
  export async function updateOccurrenceSpanCode(OccurrenceSpanCode) {
    if (OccurrenceSpanCode) {
      return await ApiService.put(url + "/" + OccurrenceSpanCode.id, OccurrenceSpanCode as never)
        .then((res) => {
          return res.data;
        })
        .catch(({ response }) => {
          processErrors(response);
        });
    }
  }